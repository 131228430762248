<template>
  <div class='box flex flex-wrap'>
    <div class="box_item radius8 flex col-center mt20" v-for="(item,i) in list" :key="i">
      <div class="fs16 flex row-column col-center row-center w83 text-center">
        <span class="w64">{{item.title}}</span>
      </div>
      <div class="line"></div>
      <div class="flex flex1 row-center col-center relative">
        <div class="fs48 color-q fm-n mr8">{{item.num}}</div>
        <div :class="['fs16  color-q',i==3?'last_item':'']">{{item.unit}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [{
        title: '出雾直径',
        num: '5-6',
        unit: 'm'
      }, {
        title: '消杀水箱容积',
        num: '22',
        unit: 'L'
      }, {
        title: '最大喷雾量',
        num: '3.5',
        unit: 'L/H'
      }, {
        title: '攀爬角度',
        num: '5',
        unit: '°'
      },]
    };
  },

  created () { },

  mounted () { },

  methods: {}
}

</script>
<style lang='scss' scoped>
.box_item {
  width: 48%;
  height: 77px;
  background: linear-gradient(
    90deg,
    rgba(19, 250, 239, 0.3) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  .line {
    width: 1px;
    height: 48px;
    opacity: 0.2;
    border: 2px solid #ffffff;
  }
}
.box_item:nth-child(odd) {
  margin-right: 20px;
}
.last_item {
  position: absolute;
  top: 0;
  left: 58%;
}
</style>