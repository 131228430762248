<template>
  <div class=''>
    <div class="tabListHeader text-center">
      {{name}}
      <div class="tabListHeader_after"></div>
    </div>
    <el-carousel height="170px" indicator-position='none' @change='changeCarousel'>
      <el-carousel-item v-for="(item,i) in netWorkState" :key="i">
        <div class="flex row-around mt29">
          <div :class="['flex row-column col-center item_box1',item.newWorkState==100?'on_line_box':'']">
            <el-progress type="circle" :percentage="item.newWorkState" width="100" color='#3AC8B7' :format='netStateFn'>
            </el-progress>
            <div class="footer_box1 text-center">网络状态</div>
          </div>
          <div class="flex row-column col-center item_box2">
            <el-progress type="circle" :percentage="100" width="100" color='#1DBD72' :format='netTypeFn'></el-progress>
            <div class="footer_box2 text-center">网络类型</div>
          </div>
          <!-- <div class="flex row-column col-center item_box3">
            <el-progress type="circle" :percentage="25" width="100" color="#3890DC"></el-progress>
            <div class="footer_box3 text-center">信号强度</div>
          </div> -->
        </div>
      </el-carousel-item>
    </el-carousel>

  </div>
</template>

<script>

export default {
  name: 'networkType',
  props: {
    netWorkState: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      netTypeText: '',
      name: '',
      state: ''//网络状态
    };
  },
  created () {
  },

  mounted () { },

  methods: {
    netStateFn () {
      return this.state
    },
    netTypeFn () {
      return this.netTypeText
    },
    setName () {
      this.name = this.netWorkState[0].name
      this.netTypeText = this.netWorkState[0].parameter2
      if (this.netWorkState[0].parameter1 == 0) {
        this.state = '离线'
      } else if (this.netWorkState[0].parameter1 == 1) {
        this.state = '在线'
      } else {
        this.state = '未知'
      }
    },
    changeCarousel (val) {
      this.name = this.netWorkState[val].name
      this.netTypeText = this.netWorkState[val].parameter2
      if (this.netWorkState[val].parameter1 == 0) {
        this.state = '离线'
      } else if (this.netWorkState[val].parameter1 == 1) {
        this.state = '在线'
      } else {
        this.state = '未知'
      }
    }
  }
}

</script>
<style lang='scss' scoped>
.footer_box1,
.footer_box2,
.footer_box3 {
  margin-top: 10px;
  width: 118px;
  height: 30px;
  background: linear-gradient(
    360deg,
    rgba(8, 244, 218, 0.5) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  border-bottom: 2px solid #08f4da;
  color: #3ac8b7;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.footer_box2 {
  color: #1dbd72;
  background: linear-gradient(
    360deg,
    rgba(29, 189, 114, 0.47) 0%,
    rgba(29, 189, 114, 0) 100%
  );
  border-bottom: 2px solid #1dbd72;
}
.footer_box3 {
  color: #3890dc;
  background: linear-gradient(
    360deg,
    rgba(28, 100, 162, 0.44) 0%,
    rgba(28, 100, 162, 0) 100%
  );
  border-bottom: 2px solid #3890dc;
}
// 圆环边框的背景颜色
::v-deep .el-progress-circle__track {
  stroke: rgba(255, 255, 255, 0.2);
}
::v-deep .el-carousel__arrow {
  display: none;
}

//圆环内部文字样式
.item_box1,
.item_box2,
.item_box3,
.on_line_box {
  ::v-deep .el-progress__text {
    font-size: 24px !important;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
}
.on_line_box {
  ::v-deep .el-progress__text {
    color: #08f4da !important;
  }
}
.item_box2 {
  ::v-deep .el-progress__text {
    color: #1dbd72 !important;
  }
}
.item_box3 {
  ::v-deep .el-progress__text {
    color: #54aaf5 !important;
  }
}
</style>