<template>
  <div>
    <top :shouback="true" />
    <div class='container flex color-f'>
      <!-- 左边的盒子 -->
      <div class="box_l flex1 h-max pt37 color-f">
        <div class="mb36 pl22 pr124">
          <shebeiType :todayTime='toDayDisinfectTime' />
        </div>
        <!-- 网络状态 -->
        <div class="pl30">
          <!-- 标题 -->
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">网络状态</div>
            <div class="header_en">{{"Device network status".toUpperCase()}}</div>
          </div>
          <div class="pr138 mt15">
            <!-- <xiaodushui class="mt16" /> -->
            <NetworkType :netWorkState='netWorkState' ref="netDom" />
          </div>
        </div>
        <!-- //网络状态 -->

        <!-- 设备位置监测 -->
        <div class="mt27 pl30">
          <!-- 标题 -->
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">设备位置监测</div>
            <div class="header_en">{{"Equipment position monitoring".toUpperCase()}}</div>
          </div>
          <div class="pr141 mt10">
            <!-- <sheBeiGuiJi class="mt10" /> -->
            <AddListener :list='netWorkState' ref="addDom" />
          </div>

        </div>
        <!-- //设备位置监测 -->

        <!-- 设备信息 -->
        <div class="mt15 pl30">
          <!-- 标题 -->
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">设备信息</div>
            <div class="header_en">{{"Equipment information".toUpperCase()}}</div>
          </div>
          <div class="pr126">
            <sheBeiInfo />
          </div>
        </div>
        <!-- //设备信息 -->
      </div>
      <!-- 左边的盒子 -->

      <!-- 中间的盒子 -->
      <div class="box_center w488 h-max">
        <img src="@/assets/img/4.0/topHeader.png" class="w330 h12 mt23" style="margin:0 auto">
        <!-- 累计消毒面积 -->
        <div class="mt30 w-max countNum relative" style="z-index:2">
          <!-- <countNum style="margin:0 auto" /> -->
          <CountFlop :val='totalHours' title='累计消毒时长' suffix='h' style="margin:0 auto" />
        </div>
        <!-- //累计消毒面积 -->

        <!-- 中间旋转区域 -->
        <div class="center_rotate">
          <centerRotate />
        </div>
        <!-- //中间旋转区域 -->

        <!-- —— 近一周机器人使用记录 —— -->
        <div class="center_b relative" style="z-index:2">
          <shebeiUseList />
        </div>
        <!-- //—— 近一周机器人使用记录 —— -->
      </div>
      <!-- //中间的盒子 -->

      <!-- 右边的盒子 -->
      <div class="box_r flex1 h-max pt57 mr26">
        <!-- 每台设备累计消毒时长 -->
        <div class="pl73 pr22">
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">每台设备累计消毒时长</div>
            <div class="header_en">{{"ACCUMULATED DISINFECTION DURATION".toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div class="mt6">
            <defendStateList :showLast='false' />
          </div>
        </div>
        <!-- //每台设备累计消毒时长 -->

        <!-- 近一周消毒时长 -->
        <div class="pl73 pr22 mt20">
          <div class="header_title fs16 mb6">
            <div class="yuan"></div>
            <div class="header_ch">近一周消毒时长</div>
            <div class="header_en">{{"Duration of disinfection in recent week".toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div>
            <xiaoduArea height='h373' />
          </div>
          <!-- //组件 -->
        </div>
        <!-- //近一周消毒时长 -->

        <!-- 本月核销码数据记录 -->
        <!-- <div class="pl103 pr22 mt20">
          <div class="header_title fs16 mb6">
            <div class="yuan"></div>
            <div class="header_ch">本月核销码数据记录</div>
            <div class="header_en">{{"Monthly verification code record".toUpperCase()}}</div>
          </div>
          <div>
            <hexiaoList />
          </div>
        </div> -->
        <!-- //本月核销码数据记录 -->

        <!-- 机器人使用情况 -->
        <div class="pl103 pr22 mt20">
          <div class="header_title fs16 mb6">
            <div class="yuan"></div>
            <div class="header_ch">机器人使用情况</div>
            <div class="header_en">{{"Robot usage".toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div class="mt7">
            <robotState :list='netWorkState' />
          </div>
          <!-- //组件 -->
        </div>
        <!-- //机器人使用情况 -->
      </div>
      <!-- //右边的盒子 -->
    </div>
  </div>

</template>

<script>
import top from '@/components/top.vue'
import shebeiType from '@/views/robot/components/shebeiType'
// import sheBeiGuiJi from '@/views/robot/components/sheBeiGuiJi'
import sheBeiInfo from '@/views/robot/components/sheBeiInfo'
import defendStateList from './components/defendStateList'
import xiaoduArea from '@/components/xiaoduArea'
// import hexiaoList from '@/components/hexiaoList'
import robotState from '@/components/robotState'
import CountFlop from '@/components/CountFlop'
import centerRotate from './components/centerRotate'
import shebeiUseList from '@/views/robot/components/shebeiUseList'
// import xiaodushui from '@/views/robot/components/xiaodushui'
import NetworkType from './components/networkType/index'
import AddListener from './components/addListener/index'
import { getNetLocation, getWorkingHours } from '@/api/Robot'
import clearTime from '@/mixin/clearTime.js'
export default {
  name: 'robot',
  mixins: [clearTime],
  components: {
    top, shebeiType,
    sheBeiInfo, defendStateList, xiaoduArea,
    robotState, centerRotate, shebeiUseList, CountFlop, NetworkType, AddListener
  },
  data () {
    return {
      //累计消毒时间
      totalHours: '',
      //网络状态  设备位置
      netWorkState: [],
      //今日消毒时长
      toDayDisinfectTime: null
    };
  },

  created () {
    //获取累计消毒时长
    this.getWorkingHours()
    //网络状态，设备位置检测
    this.getNetLocation()
    this.timer = setInterval(() => {
      this.getWorkingHours()
      this.getNetLocation()
    }, 180000)
  },

  mounted () { },

  methods: {
    async getWorkingHours () {
      const { data } = await getWorkingHours()
      // let num = null
      this.toDayDisinfectTime = (data.time / 3600).toFixed(1)
      this.totalHours = (data.area / 3600).toFixed(1).replace('.', ',')

      console.log(111, this.totalHours);
      // if (data.area < 3600) {
      //   num = 0
      // } else {
      //   num = parseInt(data.area / 3600)
      // }
      // this.toOrderNum(num)
    },
    //转换千分位
    // toOrderNum (num) {
    //   num = num.toString()
    //   if (num.length < 7) {
    //     num = '0' + num
    //     this.toOrderNum(num)
    //   } else {
    //     //加逗号
    //     num = num.slice(1, 4) + ',' + num.slice(4, 7)
    //     // this.count = num.split('') 
    //     this.totalHours = num
    //   }
    // },
    async getNetLocation () {
      const { data } = await getNetLocation()
      data.forEach(item => {
        item.parameter1 == '1' ? item.newWorkState = 100 : item.newWorkState = 0
        if (item.parameter7 == 0) {
          item.state = '未运行'
        } else if (item.parameter7 == 1) {
          item.state = '运行中'
        } else {
          item.state = '未知'
        }
      })
      this.netWorkState = data
      this.$nextTick(() => {
        if (this.$refs.netDom) {
          this.$refs.netDom.setName()
        }
        if (this.$refs.addDom) {
          this.$refs.addDom.setName()
        }

      })
      // console.log(111,data);
    }
  }
}

</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  height: 1004px;
  background: #000;
  perspective: 1800px;
  .box_l {
    transform: rotateY(10deg);
    background: url("~@/assets/img/4.0/box_l.png") no-repeat;
    // background-size: 100% 100%;
    background-size: contain;
    max-width: 720px;
  }
  .box_r {
    transform: rotateY(-10deg);
    background: url("~@/assets/img/4.0/box_r.png") no-repeat;
    // background-size: 100% 100%;
    background-size: contain;
    max-width: 720px;
  }
  .center_rotate {
    margin-top: -70px;
  }
  .center_b {
    margin-left: -70px;
    margin-top: -90px;
  }
}
</style>