<template>
  <div class=''>
    <div class="tabListHeader text-center">{{name}} <div class="tabListHeader_after"></div>
    </div>
    <el-carousel height="140px" indicator-position='none' @change='changeCarousel'>
      <el-carousel-item v-for="(item,i) in list" :key="i">
        <div class="flex row-around mt15">
          <div class="flex row-column col-center">
            <div :class="['fs32 color-q h50 fw-600 lh50',item.parameter4?'numstyle':'']">
              {{item.parameter4||"未知"}}
            </div>
            <div class="footer_box1 text-center lh75">
              当前楼层
            </div>
          </div>
          <div class="flex row-column col-center">
            <div class="fs32 h50 lh50 fw-600" style="color:#F99C14">{{item.parameter6||"未知"}}</div>
            <div class="footer_box2 text-center lh75">
              当前经度
            </div>
          </div>
          <div class="flex row-column col-center">
            <div class="fs32 h50 lh50 fw-600" style="color:#1DBD72">{{item.parameter5||'未知'}}</div>
            <div class="footer_box3 text-center lh75">
              当前纬度
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

  </div>
</template>

<script>
export default {
  name: 'addListener',
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      name: ''
    };
  },

  created () { },

  mounted () {

  },

  methods: {
    setName () {
      this.name = this.list[0].name
    },
    changeCarousel (i) {
      // console.log(55, i);
      this.name = this.list[i].name
    }
  }
}

</script>
<style lang='scss' scoped>
.footer_box1,
.footer_box2,
.footer_box3 {
  margin-top: -6px;
  width: 163px;
  height: 75px;
  background: url("~@/assets/img/4.5/add1.png") no-repeat;
  background-size: 100% 100%;
}
.footer_box2 {
  background: url("~@/assets/img/4.5/add2.png") no-repeat;
  background-size: 100% 100%;
}
.footer_box3 {
  background: url("~@/assets/img/4.5/add3.png") no-repeat;
  background-size: 100% 100%;
}
::v-deep .el-carousel__arrow {
  display: none;
}
.numstyle {
  font-size: 48px !important;
  font-family: DS-Digital-BoldItalic, DS-Digital;
}
</style>