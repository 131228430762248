<template>
  <div class='box flex flex-wrap color-f relative'>
    <div class="box_item1  h106 flex row-column row-center col-center ">
      <div class="fs48 color-q fm-n">{{shebieList[3].greenNum}}</div>
      <div class="fs14 fw-500">设备总数(台)</div>
    </div>
    <div class="box_item2  h106 flex row-column row-center col-center">
      <div class="fs48 color-q fm-n">{{shebieList[3].yellowNum}}</div>
      <div class="fs14 fw500">运行中设备数(台)</div>
    </div>
    <div class="box_item3  h106 flex row-column row-center col-center">
      <div class="fs48 color-q fm-n">{{shebieList[3].redNum}}</div>
      <div class="fs14 fw500">故障设备数(台)</div>
    </div>
    <div class="box_item4  h106 flex row-column row-center col-center">
      <div class="fs48 color-q fm-n">{{todayTime}}</div>
      <div class="fs14 fw500">今日消毒时长(h)</div>
    </div>
    <div class="box_center w90 h90 radius90 fs21 fw-500 flex row-column col-center row-center">
      <div>设备</div>
      <div>类型</div>
    </div>
  </div>
</template>

<script>
//获取设备列表
import { getBind, getWorking } from '@/api/wang.js'
import clearTime from '@/mixin/clearTime.js'
export default {
  name: 'shebeiType',
  props:{
    todayTime:{
      type:String,
      required:true
    }
  },
  mixins: [clearTime],
  data () {
    return {
      shebieList: [{
        greenNum: '--'
      }, {
        greenNum: '--'
      }, {
        greenNum: '--'
      }, {
        greenNum: '--'
      }],
      todayArea: ''
    };
  },

  created () {
    this.getBind()
    //获取今日消毒面积
    this.getWorking()
    this.timer = setInterval(() => {
      this.getBind()
      this.getWorking()
    }, 180000)
  },

  mounted () {

  },

  methods: {
    async getBind () {
      const res = await getBind()
      this.shebieList = res.data
    },
    async getWorking () {
      const { data } = await getWorking()
      this.todayArea = data.time
    }
  }
}

</script>
<style lang='scss' scoped>
.box {
  width: 566px;
  background-color: rgba(0, 0, 0, 0);
  .box_item1,
  .box_item2,
  .box_item3,
  .box_item4 {
    background-color: rgba(0, 0, 0, 0);
    background: url("~@/assets/img/4.0/shebei_item_l_t.png") no-repeat;
    background-size: 100%;
    width: 281px;
  }
  .box_item2 {
    margin-left: 4px;
    background: url("~@/assets/img/4.0/shebei_item_r_t.png") no-repeat;
    background-size: 100%;
  }
  .box_item3 {
    background: url("~@/assets/img/4.0/shebei_item_l_b.png") no-repeat;
    background-size: 100%;
  }
  .box_item4 {
    margin-left: 4px;
    background: url("~@/assets/img/4.0/shebei_item_r_b.png") no-repeat;
    background-size: 100%;
  }
  .box_center {
    position: absolute;
    top: 28%;
    left: 42%;
    background: linear-gradient(
      180deg,
      rgba(20, 76, 69, 0.3) 0%,
      rgba(18, 146, 132, 0.66) 100%
    );
  }
}
</style>